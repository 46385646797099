<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2" flat>
          {{ reportRes[0] ? reportRes[0].groupName : "" }}
          <v-spacer></v-spacer>
          <v-btn tile color="primary" @click="back()" class="rounded-xl">
            {{ $t("back") }}
            <v-icon right> mdi-arrow-left </v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2 pb-0" flat>
          <DxDataGrid
            :column-hiding-enabled="true"
            id="gridContainer"
            :rtl-enabled="$i18n.locale == 'ar' ? true : false"
            :data-source="reportRes"
            :show-column-lines="showColumnLines"
            :show-row-lines="showRowLines"
            :show-borders="showBorders"
            :row-alternation-enabled="rowAlternationEnabled"
            :remote-operations="true"
            :repaint-changes-only="true"
            :allow-column-resizing="false"
            :column-min-width="50"
            @exporting="JoinedReportDataGrid_OnExporting"
          >
            <DxExport :enabled="true" />
            <DxPaging :page-size="10" />
            <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="false"
              :show-navigation-buttons="true"
              :visible="true"
            />
            <DxSearchPanel :visible="true" :placeholder="placeholder" />
            <!-- <DxColumn data-field="id" :allow-editing="false" /> -->
            <DxColumn
              data-field="memberName"
              id="txtRight"
              :caption="$t('name')"
            />
            <DxColumn
              data-field="joinedStatusText"
              :caption="$t('حالة الانضمام')"
              cell-template="joinedStatusText"
            />
            <template #joinedStatusText="{ data }">
              <v-chip
                outlined
                :color="data.data.joinedStatus == 0 ? 'red' : 'green'"
                >{{ data.data.joinedStatusText }}</v-chip
              >
            </template>

            <DxColumn data-field="joinDate" :caption="$t('تاريخ الانضمام')" />
            <DxColumn
              data-field="totalOnlineTime"
              :caption="$t('وقت التواجد الكلي')"
            />
          </DxDataGrid>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
  DxColumnChooser,
  DxLoadPanel,
  DxExport,
} from "devextreme-vue/data-grid";
import "whatwg-fetch";
import moment from "moment";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

export default {
  props: ["id"],
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxColumnChooser,
    DxLoadPanel,
    DxExport,
  },
  data() {
    return {
      dialog: false,
      placeholder: this.$t("search"),
      rtlEnabled: true,
      showColumnLines: true,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      overlay: false,
      pageSizes: [5, 10, 20],
      reportRes: [],
    };
  },
  created() {
    this.getDegree();
  },
  methods: {
    JoinedReportDataGrid_OnExporting(e) {
      let groupName = "";
      let ownerName = "";
      let datex = "";
      const sb = [];

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        keepColumnWidths: false,
        topLeftCell: { row: 2, column: 1 },
        customizeCell: function (options) {
          const { gridCell } = options;
          // console.log(gridCell);
          const { excelCell } = options;

          if (gridCell.rowType === "data") {
            excelCell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            excelCell.alignment = { wrapText: true };
            groupName = gridCell.data.groupName;
            ownerName = gridCell.data.ownerName;
            datex = moment(gridCell.data.datex).format("YYYY/MM/DD - hh:mm A");

            if (gridCell.column.dataField === "joinDate") {
              excelCell.value = gridCell.value;
              excelCell.alignment = {
                vertical: "middle",
                wrapText: true,
                horizontal: "center",
              };
              // excelCell.width = 11;
              // excelCell.height = 30;
            }
            if (gridCell.column.dataField === "totalOnlineTime") {
              excelCell.value = gridCell.value;
              excelCell.alignment = {
                vertical: "middle",
                wrapText: true,
                horizontal: "center",
              };
              // excelCell.width = 11;
              // excelCell.height = 30;
            }
            if (gridCell.column.dataField === "joinedStatusText") {
              excelCell.value = gridCell.value;
              if (gridCell.value === "غائب") {
                excelCell.font = { color: { argb: "FF0000" } };
              }

              excelCell.alignment = { horizontal: "center" };
            }
          }
        },
      })
        .then((dataGridRange) => {
          // header

          // worksheet.spliceColumns(1, 3);

          worksheet.columns.forEach((column, i) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
              const columnLength = cell.value
                ? cell.value.toString().trim().length
                : 10;
              if (columnLength > maxLength) {
                maxLength = columnLength;
              }
            });
            column.width = maxLength < 10 ? 10 : maxLength + 1;
          });

          ["A2", "B2", "C2", "D2"].map((key) => {
            worksheet.getCell(key).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "eeeeee" },
            };

            worksheet.getCell(key).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });

          const headerRow = worksheet.getRow(1);
          headerRow.height = 75;
          worksheet.mergeCells(1, 1, 1, 4);

          sb.push(`اسم الاستاذ: ${ownerName} \n`);
          sb.push(`اسم المجموعة: ${groupName} \n `);
          sb.push(`تاريخ الانشاء: ${datex} `);
          // console.log(sb);

          headerRow.getCell(1).value = sb.toString();
          headerRow.getCell(1).font = { size: 14 };
          headerRow.getCell(1).alignment = {
            vertical: "middle",
            wrapText: true,
            horizontal: "center",
          };
        })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "JoinedReport.xlsx"
            );
          });
        });

      // e.cancel = true;
    },

    getDegree() {
      this.overlay = true;
      this.axios
        .get(`ChatGroups/JoinedMembersReport?groupGuid=${this.id}`)
        .then((res) => {
          this.reportRes = res.data.data;
          //   console.log(res);

          this.reportRes.forEach((e) => {
            if (e.joinDate) {
              e.joinDate = moment(e.joinDate).format("YYYY/MM/DD - h:mm a ");
            } else {
              e.joinDate = "لم يتم الانضمام ";
            }
          });
        })
        .catch(() => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" >
#txtRight {
  text-align: right !important;
}
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

</style>
